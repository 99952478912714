import React, { ReactNode, useMemo, useState } from 'react';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Grid,
  Toolbar,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppbarButton } from 'components/atoms/AppbarButton';
import { MenuProvider } from 'providers/MenuProvider';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { MenuDescriptor } from 'types/appbar';

// const doNothing = () => {};

export const AppMenu: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<{
    menuName: string | undefined;
    menuAnchor: HTMLElement | undefined;
  }>({
    menuName: undefined,
    menuAnchor: undefined,
  });
  const [menuComponent, setMenuComponent] = useState<ReactNode | undefined>(
    undefined,
  );

  const setMenu = (
    anchor: HTMLElement | undefined,
    menu: ReactNode | undefined,
  ) => {
    const menuText = anchor?.textContent;
    const menuName = menuText === '' ? 'Profile' : (menuText ?? undefined);
    setAnchor({ menuName, menuAnchor: anchor });

    setMenuComponent(menu);
  };

  const handleClose = () => {
    setAnchor({ menuName: undefined, menuAnchor: undefined });
  };

  const { isAdmin, logout } = useAuth();

  const AppMenuItems = useMemo(() => {
    return [
      {
        menuName: 'Profile',
        content: <MenuIcon />,
        menuItems: [
          {
            name: 'profile',
            content: 'Profile',
            action: () => navigate('/profile'),
          },
          {
            name: 'logout',
            content: 'Sign Out',
            action: () => logout(),
          },
        ],
      },
      {
        menuName: 'Schedule',
        content: 'Schedule',
        menuItems: [
          {
            name: 'individual',
            content: 'Individual',
            action: () => navigate('/schedule/partner'),
          },
          {
            name: 'group',
            content: 'Group',
            action: () => navigate('/schedule'),
          },
          {
            name: 'requests',
            content: 'Requests',
            adminOnly: true,
            action: () => navigate('/schedule/requests'),
          },
          {
            name: 'holidays',
            content: 'Holidays',
            action: () => navigate('/schedule/holidays'),
          },
          {
            name: 'shifts',
            content: 'Shifts',
            adminOnly: true,
            action: () => navigate('/schedule/shifts'),
          },
        ],
      },
      {
        menuName: 'Reports',
        content: 'Reports',
        menuItems: [
          {
            name: 'compPoints',
            content: 'Comp Points',
            action: () => navigate('/reports/compensation'),
          },
          {
            name: 'shifts',
            content: 'Partner Shifts',
            action: () => navigate('/reports/shifts'),
          },
        ],
      },
      {
        menuName: 'Partners',
        content: 'Partners',
        action: () => navigate('/partners'),
      },
      {
        menuName: 'Documents',
        content: 'Documents',
        action: () => navigate('/documents'),
      },
    ] as Array<MenuDescriptor>;
  }, [logout, navigate]);

  return (
    <Box p={theme.spacing(4)}>
      <MenuProvider value={{ setMenu, ...anchor }}>
        <AppBar
          sx={{
            position: 'sticky',
            zIndex: 10,
            maxWidth: '80vw',
            margin: 'auto',
          }}>
          <ClickAwayListener onClickAway={handleClose}>
            <Toolbar>
              <Grid container alignItems="center" justifyContent="center">
                {AppMenuItems.map(
                  (menuButton, index) =>
                    (!menuButton.adminOnly || isAdmin) && (
                      <AppbarButton
                        key={menuButton.menuName}
                        action={menuButton.action}
                        align={index === 0 ? 'left' : 'default'}
                        style={index === 0 ? 'icon' : 'default'}
                        menuItems={menuButton.menuItems}>
                        {menuButton.content}
                      </AppbarButton>
                    ),
                )}
              </Grid>
            </Toolbar>
          </ClickAwayListener>
          {menuComponent}
        </AppBar>
      </MenuProvider>
    </Box>
  );
};
