import { convertDateFromUTC, dateToString } from './date';

export const clearStorage = () => {
  sessionStorage.clear();
};

export const setStorageItem = <T>(
  key: string,
  value: T,
  serialize: (v: T) => string,
) => {
  sessionStorage.setItem(key, serialize(value));
};

export const getStorageItem = <T>(
  key: string,
  defaultValue: T,
  deserialize: (s: string) => T,
) => {
  const value = sessionStorage.getItem(key);
  return value ? deserialize(value) : defaultValue;
};

export const setStorageDate = (key: string, value: Date) => {
  return setStorageItem(key, value, dateToString);
};

export const getStorageDate = (key: string, defaultValue: Date) => {
  return getStorageItem(key, defaultValue, (dateString) =>
    convertDateFromUTC(new Date(dateString)),
  );
};
