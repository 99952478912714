import React, { ReactNode } from 'react';
import {
  Box,
  lighten,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { isFriday, isWeekend } from 'date-fns';

import { CompDate } from 'openapi';
import { nbsp } from 'util/unicode';

export const colorMap = (theme: Theme) => [
  { shift: 'OB CALL', color: theme.palette.primary },
  { shift: 'OR CALL', color: theme.palette.secondary },
  { shift: 'CV CALL', color: theme.palette.orange },
  { shift: 'Float/ OR2', color: theme.palette.yellow },
];

export const mapShiftColor = (
  theme: Theme,
  shift: string,
  isWeekendShift: boolean,
) => {
  const mapped = colorMap(theme).find((e) => e.shift === shift);
  if (mapped === undefined) {
    return undefined;
  }
  return isWeekendShift
    ? lighten(mapped.color.dark, 0.5)
    : lighten(mapped.color.dark, 0.8);
};

export const BasicCell: React.FC<{
  children?: ReactNode;
  width?: number;
  border?: boolean;
  background?: string;
}> = ({ children, width, border = true, background }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'center',
        border: border ? '1.5px solid' : undefined,
        borderColor: border ? theme.palette.grey[600] : undefined,
        backgroundColor: background,
        width: theme.spacing((width ?? 1) * 4),
        height: theme.spacing(5),
        lineHeight: theme.spacing(5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
      {children ?? nbsp}
    </Box>
  );
};

export const InvisibleCell: React.FC<{ width?: number }> = ({ width }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: theme.spacing((width ?? 1) * 4),
        height: theme.spacing(5),
      }}>
      {nbsp}
    </Box>
  );
};

export const PointsCell: React.FC<{ day: CompDate }> = ({ day }) => {
  const theme = useTheme();
  const date = new Date(day.date);
  const weekendShading = isFriday(date) || isWeekend(date);
  const sx: SxProps = {
    backgroundColor: mapShiftColor(theme, day.shift, weekendShading),
  };

  const points =
    day.annotation === 'V' || day.annotation === 'NW' ? undefined : day.points;

  return <SplitCell points={points} annotation={day.annotation} sx={sx} />;
};

export const SplitCell: React.FC<{
  annotation?: string;
  points?: number;
  wide?: boolean;
  sx?: SxProps;
}> = ({ annotation, points, wide, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'center',
        border: '1px solid',
        borderColor: theme.palette.grey[600],
        width: theme.spacing(wide ? 8 : 4),
        height: theme.spacing(5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...sx,
      }}>
      <Box pt={0.5}>
        {annotation && points === undefined
          ? nbsp
          : (points?.toLocaleString() ?? nbsp)}
      </Box>
      <Box
        sx={{
          height: theme.spacing(2),
        }}>
        <Typography fontSize="0.6rem">{annotation ?? nbsp}</Typography>
      </Box>
    </Box>
  );
};

export const TotalsCell: React.FC<{
  period?: string;
  annotation?: string;
  points?: number;
}> = ({ period, annotation, points }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: theme.spacing(20),
        height: theme.spacing(10),
      }}>
      <Box>
        <Typography fontSize="0.8rem">{period}</Typography>
      </Box>
      <Box>
        <Typography fontSize="1.8rem">
          {annotation && !points ? nbsp : (points?.toLocaleString() ?? nbsp)}
        </Typography>
      </Box>
      <Box
        sx={{
          height: theme.spacing(4),
        }}>
        <Typography fontSize="1rem">{annotation ?? nbsp}</Typography>
      </Box>
    </Box>
  );
};
